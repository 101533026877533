import React from "react";
import SEO from "../../components/seo";
import NotFound from "../../components/not-found";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "scss/pages/404.scss";

const NotFoundPage = ({ data }) => {
    const { datoCmsNotFoundPage } = data;

    return (
        <>
            <SEO title="404: Not found" />
            <Helmet>
                <body className="pageNotFound" />
            </Helmet>
            <NotFound
                data={datoCmsNotFoundPage}
                locale={datoCmsNotFoundPage.locale}
            />
        </>
    );
};

export default NotFoundPage;

export const query = graphql`
    query notfoundpageDataNl {
        datoCmsNotFoundPage(locale: { eq: "nl" }) {
            alternativeLinks {
                ... on DatoCmsArticle {
                    id
                    menuTitle
                    slug
                }
            }
            locale
            titleH1
            description
            mapTextLabel
            homeTextLabel
        }
    }
`;
